

export default () => {

    return (
        <section className="banner">
            <div className="background">
                <video loading="lazy" aria-label="Banner con un video sobre Realidad Aumentada" src="./assets/videos/sanolivar-banner.webm" autoPlay muted playsInline loop />
            </div>
            <h1 className="logo">
                <img src="./assets/images/logo-sanolivar-vertical-light.avif" alt="Logo Sanolivar" />
            </h1>
        </section>
    )
}