import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './sanolivar-style.css';
// import components
import Header from './components/Header';
import BannerHome from './components/BannerHome';
import LinkedinNews from './components/LinkedinNews';
import Form from './components/Form';
import Footer from './components/Footer';
import Slider from './components/Slider';
import { assets, root } from './utils/Path';
import Name from './utils/Name';
import Chatbot from './components/Chatbot';

const assetsHome = `${assets}/images/home/`;

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <Header active={"Inicio"} />
    <BannerHome />
    <LinkedinNews />
    {/* <NavHome /> */}
    <section className='compressed' data-aos="fade-up">
      <h2>Productos y Servicios</h2>
      <div className="exposer">
        <div className="card-expo expanded flow">
          <img loading="lazy" src={`${assetsHome}innovacion.webp`} alt='Robot con inteligencia artificial' aria-label='Robot con inteligencia artificial' title='Robot con inteligencia artificial' />
          <div>
            <div>
              <h3>Innovación & Servicios</h3>
            </div>
            <button><a role='link' title="Ir a Innovación y Servicios" href={`${root}innovacion.html`}></a>Ver Más</button>
          </div>
        </div>
        <div className="card-expo expanded flow">
          <img loading="lazy" src={`${assetsHome}infraestructura.webp`} alt='Datacenter pulcro y limpio' aria-label='Datacenter pulcro y limpio' title='Datacenter pulcro y limpio' />
          <div>
            <div>
              <h3>Infraestructura Tecnológica</h3>
            </div>
            <button><a role='link' title="Ir a Infraestructura tecnológica" href={`${root}infraestructura.html`}></a>Ver Más</button>
          </div>
        </div>
        <div className="card-expo expanded flow">
          <img loading="lazy" src={`${assetsHome}clientes.webp`} alt='Empresario dialogando con uno de sus clientes' aria-label='Empresario dialogando con uno de sus clientes' title='Empresario dialogando con uno de sus clientes' />
          <div>
            <div>
              <h3>Clientes</h3>
            </div>
            <button><a role='link' title="Ir a Nosotros" href={`${root}nosotros.html#clientes`}></a>Ver Más</button>
          </div>
        </div>
      </div>
    </section>

    <section className='fw bg-alt nopad'>
      <h2>Nuestros Colaboradores</h2>
      <Slider content={
        <div data-aos="fade-left">
          <div className='witness stars5'>
            <p>Trabajar en {Name} se ha traducido en una oportunidad para aprender algo nuevo cada día. Cuento con el apoyo para crecer personal y profesionalmente, lo que me motiva a mejorar. Nuestros valores corporativos están alineados con los míos y me siento parte importante de la empresa</p>
            <div>
              <img loading="lazy" src="./assets/images/placeholder.avif" alt="foto perfil cliente" aria-label="foto perfil cliente" title="foto perfil cliente" />
              <span>Lida Fonseca</span>
              <p>Bogotá</p>
            </div>
          </div>
          <div className='witness stars5'>
            <p>Mi experiencia en {Name} ha sido enriquecedora, todos los conocimientos y formación que he adquirido en este tiempo, la orientación de trabajo en equipo, mejora continua, pilares y valores corporativos han contribuido a continuar mi formación profesional y personal.</p>
            <div>
              <img loading="lazy" src="./assets/images/placeholder.avif" alt="foto perfil cliente" aria-label="foto perfil cliente" title="foto perfil cliente" />
              <span>Adriana Beltrán</span>
              <p>Bogotá</p>
            </div>
          </div>
          <div className='witness stars5'>
            <p>Ser parte del equipo de {Name} se ha convertido en uno de los mejores retos y logros en mi vida profesional, el trabajo en equipo, respaldo de la empresa y el constante aprendizaje me permite una proyección profesional y crecimiento personal junto con la compañía que me motiva e impulsa cada día.</p>
            <div>
              <img loading="lazy" src="./assets/images/placeholder.avif" alt="foto perfil cliente" aria-label="foto perfil cliente" title="foto perfil cliente" />
              <span>Andrea Cadena</span>
              <p>Bogotá</p>
            </div>
          </div>
          <div className='witness stars5'>
            <p>Ser parte de {Name} me ha presentado desafíos que han enriquecido mi experiencia profesional. Mantenerme actualizado con las últimas tecnologías es solo el primer paso, el verdadero desafío radica en encontrar cómo aprovecharlas de manera innovadora y aún más desafiante es el proceso de transformar esas ideas en soluciones.</p>
            <div>
              <img loading="lazy" src="./assets/images/placeholder.avif" alt="foto perfil cliente" aria-label="foto perfil cliente" title="foto perfil cliente" />
              <span>Eric Movilla</span>
              <p>Bogotá</p>
            </div>
          </div>
        </div>
      } customClass={'compressed'} />
    </section>

    <Form
      title={'Soluciones basadas en conocimiento'}
      content={'Construimos soluciones innovadoras y tecnológicas con liderazgo y creatividad para empresas colombianas con fuertes valores.'}
      img={{ src: `${assetsHome}contacto.webp`, alt: 'Hombre usando un computador' }}
      form={[
        {
          label: 'Nombre contacto',
          type: 'text',
          id: 'nombre',
          placeholder: 'Ingrese su nombre aquí'
        },
        {
          label: 'Correo electrónico',
          type: 'email',
          id: 'email',
          placeholder: 'Ingrese su correo aquí'
        },
        {
          label: 'Mensaje',
          type: 'textarea',
          id: 'mensaje',
          placeholder: 'Déjanos un mensaje aquí'
        },
        {
          label: 'Enviar Mensaje',
          type: 'submit',
          id: 'subtmit'
        }
      ]}
      redirect={`${root}index.html`}
    />
    <Footer />
    <Chatbot />
  </StrictMode>
)
